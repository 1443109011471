
import styles from './styles.module.scss'
import {Container} from "../../components/containerAzul/container";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";

const entradas = [
    "retirada_e_guarda",
    "acessorios" ,
    "servicos",
    "integracao",
    "viagem_nacional",
    "despacho_aduaneiro",
    "viagem_internacional",
    "sinistros_e_avarias",
    "faturamento",
]

const values = [
    "liberado",
    "programado",
    "em servico",
    "transito",
    "checkin",
    "seguradora",
    "sinistro",
    "canal verm.",
    "aguardando"
]

function makeObjects() {
    const list = []

    for (let j = 0; j < entradas.length; j++) {
        const object = {
            entrada: "",
            valores: []
        }
        object.entrada = entradas[j]

        for (let i = 0; i < 4; i++) {
            let index = Math.floor(Math.random() * (values.length))
            object.valores.push(values[index])
        }
        list.push(object)
    }

    return list
}

export const Chassi = () => {
    // const date = Date.now().toString()

    const objects = makeObjects()

    const navigate = useNavigate()


    useEffect(() => {
        const token = localStorage.getItem("token")
        if (!token) {
            navigate("/login")
        }
    }, [navigate])

    function handleReload() {
        window.location.reload()
    }

    function handleNavigate() {
        navigate("/")
    }

    return (
        <>
            <Container>

            <div className={styles.wrapper}>
                <div>
                    <div className={styles.chassiInfo}><span>Chassi: </span> <span> 9 BW SU19F0 8 B302149</span></div>
                    <div className={styles.chassiInfo}><span>Fabricante: </span> <span>Volvo Trucks</span></div>
                    <div className={styles.chassiInfo}>
                        <span>Tempo: </span> <span> {Intl.DateTimeFormat('pt-BR', {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                        hour: "numeric",
                        minute: "numeric"
                    }).format(Date.now())} </span></div>
                </div>

                <div>
                    <div>
                        {objects.map(item => (
                            <div key={item.entrada} className={styles.infoBlock}>
                                <span className={styles.entrada}>{item.entrada.toUpperCase()}:</span>

                                <div>
                                {item.valores.map(valor => (
                                  <span key={crypto.randomUUID().toString()}
                                        className={valor === "sinistro" || valor === "canal verm." ?
                                            styles.redFlag : styles.infoSpan}>{valor}</span>
                                ))}
                                </div>

                            </div>
                        ))}
                    </div>

                </div>
        </div>
            <div className={styles.buttonContainer}>
                <button onClick={handleReload}>ATUALIZAR PAGINA</button>
                <button onClick={handleNavigate}>NOVA PESQUISA</button>
            </div>

        </Container>
        </>
    )
};