import {Header} from "./components/header";
import {MyRouter} from "./routes";

// const chassis = [
//     {
//     chassi: "9 BW SU19F0 8 B302149",
//     fabricante: "VOLVO TRUCKS",
//     tempo: Date.now().toLocaleString(),
//     retirada_e_guarda: valores[randomIndex],
//     acessorios: valores[randomIndex],
//     servicos: valores[randomIndex],
//     integracao: valores[randomIndex],
//     viagem_nacional: valores[randomIndex],
//     despacho_aduaneiro: valores[randomIndex],
//     viagem_internacional: valores[randomIndex],
//     sinistros_e_avarias: valores[randomIndex],
//     faturamento: valores[randomIndex],
//
//     // "aguardando"
// }]


// const valores = [
//     "liberado",
//     "programado",
//     "em servico",
//     "transito",
//     "checkin",
//     "seguradora",
//     "sinistro",
//     "canal verm.",
//     "aguardando"
// ]
//
// const randomIndex = Math.floor(Math.random() * valores.length +1)


function App() {
  return (
    <>
        <Header/>
        <MyRouter/>
    </>
  );
}

export default App;
