import {xml2js} from 'xml-js'


import styles from './styles.module.scss'
import {useState} from "react";
import axios from "axios";

export const XmlInput = () => {
    const [file, setFile] = useState([])

    async function handleSubmit(e) {
        e.preventDefault()
        const reader = new FileReader();
        reader.onloadend = async () => {
            const tal = reader.result
            const cleiton = xml2js(tal, {compact: true})
            // console.log(cleiton);
            const obj = {
                natureza_operacao: cleiton.nfeProc.NFe.infNFe.ide.natOp._text,
                data_e_hora: cleiton.nfeProc.NFe.infNFe.ide.dhEmi._text,
                emissor: cleiton.nfeProc.NFe.infNFe.emit.xNome._text,
                cnpj_emissor: cleiton.nfeProc.NFe.infNFe.emit.CNPJ._text,
                fantasia_emissor: cleiton.nfeProc.NFe.infNFe.emit.xFant._text,
                destinatario: cleiton.nfeProc.NFe.infNFe.dest.xNome._text,
                cnpj_destinatario: cleiton.nfeProc.NFe.infNFe.dest.CNPJ._text,
                email_destinatario: cleiton.nfeProc.NFe.infNFe.dest.email._text,
            }
            try {
                const result = await axios.post('https://svd-back.onrender.com/nota', obj)
                console.log(result.data)
            } catch (e) {
                alert(e)
            }
            console.log(JSON.parse(JSON.stringify(obj)))
        }
        reader.readAsText(file[0])
    }

    return (
        <div className={styles.pageContainer}>
            <form onSubmit={handleSubmit}>
                <input name={'file'} type="file" onChange={e => setFile(e.target.files)}/>
                <input type="text" name={'text'}/>
                <button type='submit'>Enviar</button>
            </form>


        </div>
    );
};