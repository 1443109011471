import styles from "./styles.module.scss";

export const Container = ({children}) => {
    return (
        <div className={styles.container}>
            <p>AREA DO CLIENTE: EMPRESA DE TESTE NUMERO 1</p>
            <hr/>
            <p>MONITORAMENTO DE CICLOS LOGISTICOS</p>

            {children}
        </div>
    );
};