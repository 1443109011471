import {Container} from "../../components/containerAzul/container";

import styles from './styles.module.scss'
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

export const Home = () => {
    const [inputValue, setInputValue] = useState("")

    const navigate = useNavigate()

    useEffect(() => {
        const token = localStorage.getItem("token")
        if (!token) {
            navigate("/login")
        }
    }, [navigate])

    function handleSelect() {
        navigate("/chassi")
    }


    function handleNavigate() {
        navigate("/chassi")
    }

    return (
        <div>
            <Container>
                <div className={styles.wrapper}>
                    <div className={styles.left}>
                        <label>ESCOLHA UM CHASSI</label>
                        <select onChange={handleSelect} className='truck-chassi' defaultValue={"9 BW SU19F0 8 B302148"}>
                            <option value="1">9 BW SU19F0 8 B302148</option>
                            <option value="2">9 BW SU19F0 8 B302149</option>
                            <option value="3">9 BW SU19F0 8 B302150</option>
                            <option value="4">9 BW SU19F0 8 B302151</option>
                            <option value="5">9 BW SU19F0 8 B302152</option>
                            <option value="6">9 BW SU19F0 8 B302153</option>
                            <option value="7">9 BW SU19F0 8 B302154</option>
                            <option value="8">9 BW SU19F0 8 B302155</option>
                            <option value="9">9 BW SU19F0 8 B302156</option>
                            <option value="10">9 BW SU19F0 8 B302157</option>
                            <option value="10">9 BW SU19F0 8 B302158</option>
                            <option value="10">9 BW SU19F0 8 B302159</option>
                            <option value="10">9 BW SU19F0 8 B302160</option>
                            <option value="10">9 BW SU19F0 8 B302161</option>
                            <option value="10">9 BW SU19F0 8 B302162</option>
                            <option value="10">9 BW SU19F0 8 B302163</option>
                            <option value="10">9 BW SU19F0 8 B302164</option>
                        </select>
                    </div>

                    <div className={styles.right}>
                        <label>OU DIGITE O CÓDIGO</label>
                        <input type="text" className='put-code-chassi' value={inputValue} onChange={event => setInputValue(event.target.value)}></input>

                        <div>
                            <button onClick={() => setInputValue("")} >LIMPAR CAMPO</button>
                            <button onClick={handleNavigate} >PESQUISAR</button>
                        </div>
                        <button>VOLTAR AO MENU</button>

                    </div>

                </div>

            </Container>
        </div>
    );
};