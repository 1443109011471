import {Route, Routes} from "react-router-dom";
import {Chassi} from "../pages/chassi/chassi";
import {Home} from "../pages/home/home";
import {Login} from "../pages/login/login";
import {XmlInput} from "../pages/xmlInput/xmlInput";

export const MyRouter = () => {
    return (
        <Routes>
            <Route path={"/"} element={<Home/>}></Route>
            <Route path={"/login"} element={<Login/>}></Route>
            <Route path={"/chassi"} element={ <Chassi/>}></Route>
            <Route path={"/xml"} element={ <XmlInput/>}></Route>
        </Routes>
    )
}