import {AiOutlineMenu, AiOutlineClose} from 'react-icons/ai'
import {useState} from "react";

import styles from './header.mobile.module.scss'

export const HeaderMobile = () => {
    const [open, setOpen] = useState(false)

    function toggleMenu() {
        setOpen(!open)
    }

    if(!open) {
        return (
            <>
                <i> <AiOutlineMenu size={15} onClick={toggleMenu}/> </i>
            </>
        );
    }

    if(open) {
        return (
            <div className={styles.mobileContainer}>
                <i className={styles.closeIcon}> <AiOutlineClose size={20} onClick={toggleMenu}/> </i>
                <nav>
                    <ul id="navigation">
                        <li href="https://gruposvd.com.br/empresa.php">Empresa</li>
                        <li href="https://gruposvd.com.br/servicos.php">Nossos Serviços</li>
                        <li href="https://gruposvd.com.br/infraestrutura.php">Infraestrutura</li>
                        <li href="https://gruposvd.com.br/politica-integrada.php">Política Integrada e Certificações</li>
                        <li href="https://gruposvd.com.br/galeria.php">Galeria</li>
                        <li href="https://gruposvd.com.br/contato.php">Contato</li>
                        <li href="https://gruposvd.com.br/blog.php">Blog</li>
                    </ul>
                </nav>
            </div>
        )
    }
};