import styles from './styles.module.scss'
import {useState, } from "react";
import {useNavigate} from "react-router-dom";

export const Login = () => {
    const [login, setLogin] = useState("")
    const [senha, setSenha] = useState("")

    const navigate = useNavigate()

    function handleLogin() {
        if (login === "admin" && senha === "admin") {
            localStorage.setItem("token", JSON.stringify(crypto.randomUUID().toString()))
            navigate("/")
            return
        }
        window.alert("login incorreto")
    }

    return (
        <>
        <div className={styles.wrapper}>
            <div className={styles.clientArea}>
                <h3 className={styles.clientAreaTitle}>Área do cliente</h3>
            </div>

            <div className={styles.loginContainer}>
                <p>Deseja acessar a área exclusiva para clientes SVD? <br/> Basta informar seu login e senha:</p>
                <label htmlFor="login">Login</label>
                <input type="text" id={"login"} value={login} onChange={event => setLogin(event.target.value)}/>
                <label htmlFor="senha">Senha</label>
                <input id={"senha"} type="text" value={senha} onChange={event => setSenha(event.target.value)}/>
                <button onClick={handleLogin}>ENTRAR</button>
            </div>
        </div>
        </>
    );
};