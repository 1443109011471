import { FaFacebookF } from "react-icons/fa"
import { BsInstagram } from "react-icons/bs"
import { IoIosArrowForward } from "react-icons/io"

import styles from './header.module.scss'
import {useEffect, useState} from "react";
import {HeaderMobile} from "./header/header.mobile";


function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });
    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.outerWidth,
                height: window.innerHeight,
            });
        }
        // Add event listener
        window.addEventListener("resize", handleResize);
        // Call handler right away so state gets updated with initial window size
        handleResize();
        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
}

export const Header = () => {

    const {width} = useWindowSize()

    // if (width < 426) {
    //     return <HeaderMobile/>
    // }

    return (
        <div className={styles.container}>
        <div className={styles.headerTop}>
            <div className={styles.topContainer}>
                <div className={styles.socialMedia}>

                    <a href="https://www.facebook.com/svdtransportes/">
                        <i> <FaFacebookF size={15} /> </i>
                    </a>
                    <a href="https://www.instagram.com/svdtransportes/">
                        <i> <BsInstagram size={15} /> </i>
                    </a>
                </div>

                <div className={styles.contactList}>
                    <ul>
                        <li>
                            <a href="https://svdacessorios.com.br/">
                                <i> <IoIosArrowForward size={15} /> </i>SVD Acessórios</a>
                        </li>
                        <li>
                            <a href="https://svdseminovos.com.br/">
                                <i> <IoIosArrowForward size={15} /> </i>SVD Seminovos</a>
                        </li>
                    </ul>
                </div>
            {width < 426 ? (<HeaderMobile/>) : null}
            </div>

        </div>

            {width < 426 ? null : (

        <div className={styles.headerBottom}>
            <div className={styles.bottomContainer}>
            <div className={styles.logo}>
                <a href="https://gruposvd.com.br/index.php">
                    <img className={styles.oficialLogo} src="https://gruposvd.com.br/img/design/logo-svd.png" alt=""></img>
                </a>
            </div>

            <div className={styles.headerMainMenu}>
                <nav>
                    <ul id="navigation">
                        <li href="https://gruposvd.com.br/empresa.php">Empresa</li>
                        <li href="https://gruposvd.com.br/servicos.php">Nossos Serviços</li>
                        <li href="https://gruposvd.com.br/infraestrutura.php">Infraestrutura</li>
                        <li href="https://gruposvd.com.br/politica-integrada.php">Política Integrada e Certificações</li>
                        <li href="https://gruposvd.com.br/galeria.php">Galeria</li>
                        <li href="https://gruposvd.com.br/contato.php">Contato</li>
                        <li href="https://gruposvd.com.br/blog.php">Blog</li>
                    </ul>
                </nav>
            </div>
        </div>
        </div>
            )}
        </div>

    );
};